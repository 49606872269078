export const Default = {
  zIndex: {
    appBar: 1250
  },
  typography: {
    useNextVariants: true
  }
}

export default Default
