import { createReducer, createActions } from "reduxsauce"

const { Types, Creators } = createActions({
  login: ["email", "password", "history"],
  loginSuccess: ["session"],
  loginFailure: null,
  logout: ["history"],
  logoutSuccess: null,
  verifyToken: ["history"],
  verifyTokenSuccess: ["session"],
  verifyTokenFailure: null,
  facebookLogin: ["response", "history"],
  googleLogin: ["idToken", "history"]
})

const INITIAL_STATE = {
  verifyTokenComplete: false,
  isLoggedIn: false,
  session: {}
}

const login = state => state

const loginSuccess = (state, action) => ({
  ...state,
  session: action.session,
  isLoggedIn: true
})

const loginFailure = state => state

const logout = state => state

const logoutSuccess = state => ({
  ...state,
  isLoggedIn: false,
  session: {}
})

const verifyToken = state => state

const verifyTokenSuccess = (state, action) => ({
  ...state,
  session: action.session,
  isLoggedIn: true,
  verifyTokenComplete: true
})

const verifyTokenFailure = state => ({
  ...state,
  isLoggedIn: false,
  verifyTokenComplete: true
})

const facebookLogin = state => state

const googleLogin = state => state

export const reducer = createReducer(INITIAL_STATE, {
  [Types.LOGIN]: login,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGIN_FAILURE]: loginFailure,
  [Types.LOGOUT]: logout,
  [Types.LOGOUT_SUCCESS]: logoutSuccess,
  [Types.VERIFY_TOKEN]: verifyToken,
  [Types.VERIFY_TOKEN_SUCCESS]: verifyTokenSuccess,
  [Types.VERIFY_TOKEN_FAILURE]: verifyTokenFailure,
  [Types.FACEBOOK_LOGIN]: facebookLogin,
  [Types.GOOGLE_LOGIN]: googleLogin
})

export const AuthTypes = Types

export default Creators
