import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormControl from "@material-ui/core/FormControl"
import FormLabel from "@material-ui/core/FormLabel"
import Button from "@material-ui/core/Button"

const erd = window.erd

const styles = theme => ({
  formControl: {
    marginTop: 16,
    width: "100%"
  },
  textField: {
    marginTop: 0,
    width: "100%"
  }
})

class EntityContext extends Component {
  state = {
    ...this.props.contextDetails
  }

  handleNameChange = event => {
    const name = event.target.value
    const stage = this.props.getStage()
    stage.undoManager.startAction()

    const entity = stage.findById(this.props.contextDetails.id)
    entity.details.name = name
    stage.draw()
    this.setState({
      name
    })
  }

  handleTypeChange = event => {
    const type = event.target.value
    const stage = this.props.getStage()
    stage.undoManager.startAction()

    const entity = stage.findById(this.props.contextDetails.id)
    entity.details.type = type
    stage.draw()
    this.setState({
      type
    })
  }

  handleAddAttribute = () => {
    const stage = this.props.getStage()
    if (!stage.undoManager.hasCurrentAction()) {
      stage.undoManager.startAction()
    }

    const entity = stage.findById(this.props.contextDetails.id)
    const pt = entity.getNextAutomaticPosition()
    const attribute = stage.factory.attribute({
      x: pt.x,
      y: pt.y,
      name: "NewAttribute"
    })

    stage.addItem(attribute)
    stage.connect.items(attribute, entity)
    stage.selectItem(attribute)
    stage.draw()
    stage.invokeDirtyCallback()
  }

  render() {
    const nameTextField = input => {
      if (input) {
        setTimeout(() => {
          input.focus()
          input.select()
        }, 0)
      }
    }

    const { classes } = this.props

    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel>Entity Name</FormLabel>
            <TextField
              id="name"
              margin="normal"
              autoComplete="off"
              value={this.state.name}
              onChange={this.handleNameChange}
              inputProps={{ ref: nameTextField }}
              className={classes.textField}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel>Type</FormLabel>
            <RadioGroup value={this.state.type} onChange={this.handleTypeChange}>
              <FormControlLabel value={erd.EntityType.Regular} control={<Radio />} label="Regular" />
              <FormControlLabel value={erd.EntityType.Weak} control={<Radio />} label="Weak" />
              <FormControlLabel value={erd.EntityType.Associative} control={<Radio />} label="Associative" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button variant="outlined" onClick={this.handleAddAttribute}>
            Add Attribute
          </Button>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EntityContext)
)
